import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import lclogo from '../../images/portfolio/identity/lc-logo.png'
import palogo from '../../images/portfolio/identity/pa-logo.png'
import smlogo from '../../images/portfolio/identity/sm-logo.png'
import zapointlogo from '../../images/portfolio/identity/zapoint-logo.png'

import sflogo from '../../images/portfolio/identity/sf-logo.png'
import atglogo from '../../images/portfolio/identity/atg-logo.png'

import tamboralogo from '../../images/portfolio/identity/tambora-logo.png'
import jobsterlogo from '../../images/portfolio/identity/jobster-logo.png'

import cytrionlogo from '../../images/portfolio/identity/cytrion-logo.png'
import sevenlogo from '../../images/portfolio/identity/seven-logo.png'

import greenbrooklogo from '../../images/portfolio/identity/greenbrook-logo.png'
import lbghrlogo from '../../images/portfolio/identity/lbghr-logo.png'

const Identity = () => {
 
    return(
        <Layout>
            <SEO title="Case Studies: Logos & Identity" />
            <div className="case-study">
                <div className="container-flxuid text-center text-primary">
                    <h1 className='page-title display my-4 pb-3'>_Corporate identity</h1>
                    <p className='mb-4 text-primary-dark'>Owning a good logo is essential for any business. it is a basic foundation block upon which you can build a bigger brand. Often, your logo is the first thing a potential customer will see. First impressions are incredibly important in the world of business, a professional logo design you can make your first impression count.</p>
                </div>

                <div className="case-imgs content">
                    <ul className="case-identity-logos">

                        <li><img width='300' src={zapointlogo} alt='' /></li>
                        <li><img width='300' src={sflogo} alt='' /></li>
                        <li><img width='270' src={smlogo} alt='' /></li>

                        <li><img width='170' src={lclogo} alt='' /></li>
                        <li><img width='145'  src={atglogo} alt='' /></li>
                        <li><img width='170' src={tamboralogo} alt='' /></li>

                        <li><img width='272' src={jobsterlogo} alt='' /></li>
                        <li><img width='230' src={palogo} alt='' /></li>
                        <li><img width='258' src={cytrionlogo} alt='' /></li>

                        <li><img width='130' src={sevenlogo} alt='' /></li>
                        <li><img width='210' src={greenbrooklogo} alt='' /></li>
                        <li><img width='130' src={lbghrlogo} alt='' /></li> */}
                    </ul>
                </div>
            </div>
        </Layout>
    )
}

export default Identity
